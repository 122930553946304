<template>
  <Modal v-model="showModal" :mask-closable="false" width="1000px">
    <div slot="header">编辑媒体播放任务</div>
    <div class="edit-area">
      <div class="form-area">
        <Form ref="form" :model="form" :rules="rules" :label-width="80">
          <FormItem prop="name" label="任务名称">
            <Input type="text" v-model="form.name" placeholder="请输入名称">
            </Input>
          </FormItem>
          <FormItem prop="level" label="优先级">
            <Input type="text" v-model="form.level" placeholder="请输入优先级">
            <span slot="append">数字越大，优先级越高</span>
            </Input>
          </FormItem>
          <FormItem prop="imageTime" label="图片时长">
            <Input type="text" v-model="form.imageTime" placeholder="请输入名称">
            <span slot="append">秒</span>
            </Input>
          </FormItem>
          <FormItem prop="remark" label="播放时段1">
            <div class="edit-inline">
              开：<TimePicker v-model="form.start1" format="HH:mm" style="width: 168px"></TimePicker>
              关：<TimePicker v-model="form.end1" format="HH:mm" style="width: 168px"></TimePicker>
            </div>
          </FormItem>
          <FormItem prop="remark" label="播放时段2">
            <div class="edit-inline">
              开：<TimePicker v-model="form.start2" format="HH:mm" style="width: 168px"></TimePicker>
              关：<TimePicker v-model="form.end2" format="HH:mm" style="width: 168px"></TimePicker>
            </div>
          </FormItem>
          <FormItem prop="remark" label="播放时段3">
            <div class="edit-inline">
              开：<TimePicker v-model="form.start3" format="HH:mm" style="width: 168px"></TimePicker>
              关：<TimePicker v-model="form.end3" format="HH:mm" style="width: 168px"></TimePicker>
            </div>
          </FormItem>
          <FormItem prop="remark" label="播放时段4">
            <div class="edit-inline">
              开：<TimePicker v-model="form.start4" format="HH:mm" style="width: 168px"></TimePicker>
              关：<TimePicker v-model="form.end4" format="HH:mm" style="width: 168px"></TimePicker>
            </div>
          </FormItem>
          <FormItem prop="type" label="播放日期">
            <CheckboxGroup v-model="form.weeks">
              <Checkbox v-for="n in 7" :key="n" :label="n">{{weeks[n]}}</Checkbox>
            </CheckboxGroup>
          </FormItem>
        </Form>
      </div>
      <div class="preview-area">
        <div class="tree-area">
          <Tree ref="tree" :data="treeData" @on-select-change="nodeChange"></Tree>
        </div>
        <div class="media-area">
          <div class="media-item media-header">待选媒体列表</div>
          <div class="media-item" v-for="(item, idx) in medias" :key="idx">
            <div class="media-item-title">
              [{{mediaTypes[item.type]}}]{{item.name}}
            </div>
            <div class="media-item-btn" @click="selectItem(item)"> &gt;&gt; </div>
          </div>
        </div>
        <div class="media-area">
          <div class="media-item media-header">已选媒体列表</div>
          <div class="media-item" v-for="(item, idx) in selected" :key="idx">
            <div class="media-item-btn" @click="unselectItem(item, idx)"> &lt;&lt; </div>
            <div class="media-item-title" :title="item.name">
              [{{mediaTypes[item.type]}}]{{item.name}}
            </div>
            <div class="media-item-btn" @click="moveUp(item, idx)"> 👆 </div>
            <div class="media-item-btn" @click="moveDown(item, idx)"> 👇 </div>
          </div>
        </div>
      </div>
    </div>
    <div slot="footer">
      <!-- {{form.start1}} -->
      <Button style="margin-right: 8px" @click="cancel">取消</Button>
      <Button type="primary" :loading="loading" @click="ok">保存</Button>
    </div>
  </Modal>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'ModalMediaEdit',
  components: {
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default() { return {}; }
    },
    mp3Only: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      showModal: this.value,
      loading: false,
      isAdd: false,
      treeData: [],
      groups: [],
      medias: [],
      selected: [],
      form: {
        id: 0,
        name: '',
        type: 1,
        imageTime: 10,
        programs: [],
        weeks: [],
        days: [],
        level: 1,
        start1: '09:00',
        end1: '11:30',
        start2: '14:30',
        end2: '16:30',
        start3: '19:00',
        end3: '20:30',
        start4: '21:00',
        end4: '22:00',
      },
      rules: {
        name: { required: true, type: 'string', max: 20, message: '名称不能为空且长度不能超过20', trigger: 'blur' },
        level: { required: true, type: 'number', message: '必须设置任务优先级', trigger: 'blur' },
      },
    }
  },
  computed: {
    ...mapState('common', ['mediaTypes', 'playTaskTypes', 'weeks']),
    ...mapState('auth', ['token']),
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if (newVal) {
        this.isAdd = this.item.isAdd;
        if (!this.isAdd) {
          this.form.id = this.item.data.id;
          this.form.name = this.item.data.name;
          this.form.type = this.item.data.type;
          this.form.imageTime = this.item.data.imageTime;
          this.form.programs = this.item.data.programs;
          this.form.weeks = this.item.data.weeks;
          this.form.days = this.item.data.days;
          this.form.level = this.item.data.level;
          this.form.start1 = new Date(this.item.data.start1);
          this.form.end1 = new Date(this.item.data.end1);
          this.form.start2 = new Date(this.item.data.start2);
          this.form.end2 = new Date(this.item.data.end2);
          this.form.start3 = new Date(this.item.data.start3);
          this.form.end3 = new Date(this.item.data.end3);
          this.form.start4 = new Date(this.item.data.start4);
          this.form.end4 = new Date(this.item.data.end4);
          this.getSelectedMedias();
        } else {
          let now = new Date(new Date().format('yyyy-MM-dd 00:00:00'));
          // console.log('init date', now)
          this.form.id = 0;
          this.form.name = '';
          this.form.type = 1;
          this.form.imageTime = 10;
          this.form.programs = [];
          this.form.weeks = [];
          this.form.days = [];
          this.form.level = 1;
          this.form.start1 = now;
          this.form.end1 = now;
          this.form.start2 = now;
          this.form.end2 = now;
          this.form.start3 = now;
          this.form.end3 = now;
          this.form.start4 = now;
          this.form.end4 = now;
          this.$set(this, 'selected', []);
        }
        this.getGroups();
      }
    },
    showModal(val) {
      this.$emit('showChanged', val);
    }
  },
  methods: {
    selectItem: function (item) {
      if (this.selected.filter(p => p.id == item.id).length == 0) {
        this.selected.push({ id: item.id, name: item.name, type: item.type });
      }
    },
    unselectItem: function (item, idx) {
      this.selected.splice(idx, 1);
    },
    swapItems(arr, index1, index2) {
      arr[index1] = arr.splice(index2, 1, arr[index1])[0]
      return arr
    },
    moveUp: function (item, idx) {
      if (idx == 0) return;
      this.swapItems(this.selected, idx, idx - 1);
      // this.selected[idx] = this.selected.splice(idx - 1, 1, this.selected[idx])[0];
      // this.selected.splice(idx, 1);
    },
    moveDown: function (item, idx) {
      if (idx == this.selected.length - 1) return;
      // this.selected.splice(idx, 1);
      this.swapItems(this.selected, idx, idx + 1);
    },
    nodeChange: function (nodes, node) {
      // console.log('node change', node);
      this.selectedNode = node;
      this.getMedias();
    },
    getSelectedMedias: function () {
      if (this.form.programs.length == 0) {
        this.$set(this, 'selected', []);
        return;
      }
      this.$axios.post(`//${this.domains.trans}/media/selected`, { list: this.form.programs }).then(res => {
        if (res.code != 0) {
          // this.$Message.error(res.data);
          return;
        }
        this.$set(this, 'selected', res.data);
      });
    },
    getMedias: function () {
      let groupId = 0;
      if (this.selectedNode.id > 0) {
        groupId = this.selectedNode.id;
      }
      if (groupId == 0) {
        this.$set(this, 'medias', []);
        return;
      }
      this.$axios.post(`//${this.domains.trans}/media/operation/GetConfirm`, { groupId, type: this.mp3Only ? 2 : 0 }).then(res => {
        if (res.code != 0) {
          // this.$Message.error(res.data);
          return;
        }
        this.$set(this, 'medias', res.data);
      });
    },
    getGroups: function () {
      this.$axios.get(`//${this.domains.trans}/media/group`).then(res => {
        if (res.code != 0) {
          // this.$Message.error(res.data);
          return;
        }
        this.$set(this, 'groups', res.data);
        let tree = [];
        this.initTreeData(tree, res.data, 0);
        this.$set(this, 'treeData', tree);
      });
    },
    initTreeData: function (children, list, parentId) {
      let ps = list.filter(p => p.parentId == parentId);
      for (let p of ps) {
        let node = { id: p.id, title: p.name, data: p, children: [], expand: true }
        this.initTreeData(node.children, list, node.id);
        children.push(node);
      }
    },
    getFileType: function (mime) {
      switch (mime) {
        case "video/mp4":
        case 'audio/mp4':
        case 'video/mpeg':
          return 1;
        case 'audio/mpeg':
          return 2;
        case 'image/png':
        case 'image/gif':
        case 'image/jpeg':
          return 3;
        default:
          return 0;
      }
    },
    getFileExt: function (name) {
      let idx = name.lastIndexOf('.');
      if (idx < 0) return '';
      return name.substr(idx + 1);
    },
    fileChanged: function (e) {
      // console.log("fileChanged", a.srcElement.files);
      let file = e.target.files[0];
      let mime = file.type;
      let type = this.getFileType(mime);
      if (type == 0) {
        this.$Message.warning('未支持格式，请联系管理员');
        return;
      }
      this.url = window.webkitURL.createObjectURL(file);
      let name = file.name;
      this.form.type = type;
      this.form.mime = mime;
      this.form.size = file.size;
      this.form.ext = this.getFileExt(name);
      this.file = file;
      // console.log("fileChanged", name, mime, type, size, this.url);
    },
    videoLoaded: function (e) {
      this.audio = null;
      // console.log("videoLoaded", e);
      // console.log("videoLoaded", a.srcElement.videoWidth, a.srcElement.videoHeight);
      this.video = e.srcElement;
      this.form.width = this.video.videoWidth;
      this.form.height = this.video.videoHeight;
      this.form.time = parseInt(this.video.duration);
      this.video.play();
    },
    audioLoaded: function (e) {
      this.video = null;
      // console.log("audioLoaded", e);
      this.audio = e.srcElement;
      this.form.width = 0;
      this.form.height = 0;
      this.form.time = parseInt(this.audio.duration);
      this.audio.play();
    },
    imageLoaded: function () {
      this.video = null;
      this.audio = null;
      // console.log("imageLoaded", e, this.$refs.img.naturalWidth, this.$refs.img.naturalHeight);
      this.form.width = this.$refs.img.naturalWidth;
      this.form.height = this.$refs.img.naturalHeight;
      this.form.time = 0;
    },
    ok: async function () {
      let result = await this.$refs.form.validate();
      if (!result) {
        return;
      }
      let timeOk = false;
      // console.log('check time', this.form.start1, typeof this.form.start1, this.form.end1)
      for (let i = 1; i <= 4; i++) {
        let start = this.form[`start${i}`];
        if (typeof start == 'object') {
          start = start.format('HH:mm');
        }
        let end = this.form[`end${i}`];
        if (typeof end == 'object') {
          end = end.format('HH:mm');
        }
        if (start != end) {
          timeOk = true;
          break;
        }
      }
      if (!timeOk) {
        this.$Message.warning('请设置播放时段');
        return;
      }
      if (this.form.weeks.length == 0) {
        this.$Message.warning('请选择播放日期');
        return;
      }
      if (this.selected.length == 0) {
        this.$Message.warning('请选择播放列表');
        return;
      }
      // this.form.start1 = `2022-05-27 ${this.form.start1}00秒`;
      // this.form.start2 = `2022-05-27 ${this.form.start2}00秒`;
      // this.form.start3 = `2022-05-27 ${this.form.start3}00秒`;
      // this.form.start4 = `2022-05-27 ${this.form.start4}00秒`;
      // this.form.end1 = `2022-05-27 ${this.form.end1}00秒`;
      // this.form.end2 = `2022-05-27 ${this.form.end2}00秒`;
      // this.form.end3 = `2022-05-27 ${this.form.end3}00秒`;
      // this.form.end4 = `2022-05-27 ${this.form.end4}00秒`;
      // this.form.programs = this.selected.map(p => p.id);
      let start1 = this.form.start1;
      if (typeof start1 == 'object') {
        start1 = start1.format('HH:mm');
      }
      let start2 = this.form.start2;
      if (typeof start2 == 'object') {
        start2 = start2.format('HH:mm');
      }
      let start3 = this.form.start3;
      if (typeof start3 == 'object') {
        start3 = start3.format('HH:mm');
      }
      let start4 = this.form.start4;
      if (typeof start4 == 'object') {
        start4 = start4.format('HH:mm');
      }
      let end1 = this.form.end1;
      if (typeof end1 == 'object') {
        end1 = end1.format('HH:mm');
      }
      let end2 = this.form.end2;
      if (typeof end2 == 'object') {
        end2 = end2.format('HH:mm');
      }
      let end3 = this.form.end3;
      if (typeof end3 == 'object') {
        end3 = end3.format('HH:mm');
      }
      let end4 = this.form.end4;
      if (typeof end4 == 'object') {
        end4 = end4.format('HH:mm');
      }
      let frm = {
        id: this.form.id,
        name: this.form.name,
        type: this.form.type,
        imageTime: this.form.imageTime,
        weeks: this.form.weeks,
        days: this.form.days,
        level: this.form.level,
        start1: `2022-05-27 ${start1}:00`,
        start2: `2022-05-27 ${start2}:00`,
        start3: `2022-05-27 ${start3}:00`,
        start4: `2022-05-27 ${start4}:00`,
        end1: `2022-05-27 ${end1}:00`,
        end2: `2022-05-27 ${end2}:00`,
        end3: `2022-05-27 ${end3}:00`,
        end4: `2022-05-27 ${end4}:00`,
        programs: this.selected.map(p => p.id),
      }
      let req;
      if (this.isAdd) {
        req = this.$axios.post(`//${this.domains.trans}/media/task`, frm);
      } else {
        req = this.$axios.put(`//${this.domains.trans}/media/task/${frm.id}`, frm);
      }
      req.then(res => {
        if (res.code !== 0) {
          // this.$Message.error(res.data);
          return;
        }
        this.$Message.info('保存成功');
        this.$emit('saved');
        setTimeout(() => {
          this.showModal = false;
        }, 800);
      })
    },
    cancel() {
      this.showModal = false;
      // this.$refs.form.resetFields();
    },
  }
}
</script>
<style scoped>
.edit-inline {
  display: flex;
  align-items: center;
}
.edit-area {
  /* border: solid 1px red; */
  height: 500px;
  display: flex;
}
.form-area {
  /* border: solid 1px red; */
  width: 360px;
  flex: none;
  padding: 10px 25px 0 0;
}
.preview-area {
  /* border: solid 1px rgba(143, 142, 142, 0.404); */
  border-radius: 4px;
  width: 400px;
  flex: auto;
  display: flex;
}
.tree-area {
  border: solid 1px rgba(143, 142, 142, 0.404);
  width: 200px;
  margin-right: 10px;
  padding: 10px;
  flex: none;
  display: flex;
  /* align-items: center; */
  border-radius: 4px;
  overflow: auto;
}
.media-area {
  border: solid 1px rgba(143, 142, 142, 0.404);
  margin-right: 10px;
  width: 200px;
  flex: none;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: center; */
  border-radius: 4px;
  padding: 3px;
  overflow-y: auto;
}
.media-item {
  border: solid 1px rgba(143, 142, 142, 0.404);
  border-radius: 4px;
  height: 30px;
  flex: none;
  margin-bottom: 3px;
  display: flex;
  align-items: center;
  padding: 5px;
  cursor: pointer;
}
.media-header {
  background-color: rgba(7, 7, 241, 0.342);
  color: white;
  font-size: 20px;
}
.media-item:hover {
  background-color: rgba(109, 161, 161, 0.247);
}
.media-item-title {
  /* border: solid 1px rgba(143, 142, 142, 0.404); */
  width: 100px;
  flex: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.media-item-btn {
  border: solid 1px rgba(143, 142, 142, 0.404);
  border-radius: 4px;
  width: 25px;
  flex: none;
  color: blue;
  text-align: center;
  margin: 3px;
}
.file-control {
  margin-left: 10px;
}
.media-player {
  /* border: solid 1px red; */
  max-width: 100%;
  max-height: 100%;
}
.media-info {
  border: solid 1px rgba(143, 142, 142, 0.404);
  height: 235px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
}
.media-info-item {
  border-bottom: solid 1px rgba(143, 142, 142, 0.404);
  padding: 3px 10px;
  height: 39px;
}
</style>